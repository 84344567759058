import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FundingRequestSummaryTableService } from '@services/funding-request-summary-table/funding-request-summary-table.service';
import { BehaviorSubject, interval } from 'rxjs';
import { debounce, skip } from 'rxjs/operators';

import { ConstantsDashboardSearchSection } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Dashboard';

@Component({
    selector: 'eb-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
    @Input() placeholderText: string;
    @Input() width: string;
    @Output()
    public searchInputChanged: EventEmitter<string> = new EventEmitter<string>();
    public searchInputBox: UntypedFormControl = new UntypedFormControl();
    // QA Config
    // TODO: any QA-specific ids/etc that are needed go here
    public constantsDashboardSearchSection: typeof ConstantsDashboardSearchSection = ConstantsDashboardSearchSection;
   // ng lint is disabled to avoid member-ordering error
    /* eslint-disable */
    private defaultStr = '';
    public searchInput: BehaviorSubject<string> = new BehaviorSubject<string>(this.defaultStr);
    /* eslint-enable */
    constructor(private fundingRequestSummaryTableService: FundingRequestSummaryTableService) {
        // going through a subject for stream manipulation capability
        this.searchInput
            .pipe(
                // throttle by 250 milliseconds, but also only take the most recently emitted value
                debounce(() => interval(250)),
                // ignore the first event, since it occurs before any typing has actually been done
                // and that first event was causing the "No results found" component to show unnecessarily
                skip(1)
            )
            .subscribe((value: string) => {
                this.searchInputChanged.emit(value);
            });

        this.fundingRequestSummaryTableService.clearSearch.subscribe(() => this.onClearButtonClicked());
    }

    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSearchBarKeyUp(event: any) {
        const value = (event && event.target && event.target.value).trim() || this.defaultStr;
        this.searchInput.next(value);
    }

    public onClearButtonClicked() {
        this.searchInput.next(this.defaultStr);
        this.searchInputBox.setValue(this.defaultStr);
    }
}
